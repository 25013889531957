import React from "react";
import cx from "classnames";
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import Hero from "../components/hero/hero";
import { URLS } from "../config/urls";

import ScrollSection from "../components/scroll-section/scroll-section";
import TransitionLink from "../components/transition-link/transition-link";
import Image from "../components/image/image";

class ContributorsIndex extends React.Component {
  render() {
    let authors = get(this, "props.data.allContentfulAuthor.edges");

    authors = authors.sort((a, b) => {
      const nameA = a.node.firstname.toUpperCase();
      const nameB = b.node.firstname.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return (
      <Layout
        location={this.props.location}
        status={this.props.transitionStatus}
      >
        <Seo
          type="page"
          content={{
            title: "Contributors",
            seoDescription: "Contributors",
          }}
        />
        <ScrollSection>
          <Hero title={"Contributors".toUpperCase()} border={false} />
        </ScrollSection>
        <ScrollSection>
          <ul className={cx("authorList flex flex-wrap p-0 w-full")}>
            {authors.map(({ node }) =>
              node.type === "post" ? (
                <li key={node.slug}>
                  <TransitionLink to={`${URLS.Contributor}${node.slug}/`}>
                    {node.heroImage ? (
                      <Image
                        className={cx("authorImage block mx-auto mb-5 w-full")}
                        alt={`${node.firstname} ${node.surname} Picture`}
                        gatsbyImageData={node.heroImage.fluid}
                      />
                    ) : (
                      ""
                    )}
                    <span className={cx("authorName font-semibold")}>
                      {node.firstname} {node.surname}
                    </span>
                  </TransitionLink>
                </li>
              ) : (
                ""
              )
            )}
          </ul>
        </ScrollSection>
      </Layout>
    );
  }
}

export default ContributorsIndex;

export const pageQuery = graphql`
  query ContributorsIndexQuery {
    allContentfulAuthor {
      edges {
        node {
          id
          slug
          firstname
          role
          type
          surname
          shortBio
          heroImage: picture {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
  }
`;
